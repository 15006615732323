import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChatmessageVO } from './chat-message.vo';
import { FloatingChatService } from './floating-chat.service';
import { NewAuthService } from '../../../services/auth/new-auth-service.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-floating-chat',
  templateUrl: './floating-chat.component.html',
  styleUrls: ['./floating-chat.component.scss']
})
export class FloatingChatComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() title: string = 'Chat';
  @ViewChild('chatWindow') chatWindow: ElementRef;
  @ViewChild('messagesContainer') messagesContainer: ElementRef;
  @ViewChild('messageInput') messageInput: ElementRef;

  isMinimized: boolean = true;
  isWaitingForResponse: boolean = false;
  isTransitioning: boolean = false;
  isDragging: boolean = false;
  isResizing: boolean = false;
  resizeType: 'e' | 's' | 'se' | null = null;
  position = { x: window.innerWidth - 320, y: window.innerHeight - 48 };
  size = { width: 300, height: 400 };
  dragStart = { x: 0, y: 0 };
  initialSize = { width: 0, height: 0 };
  useTextArea: boolean = false;  // New setting for input type

  messages: ChatmessageVO[] = [];
  newMessage: string = '';
  private currentThreadId: string | null = null;

  constructor(
    private dialog: MatDialog,
    private chatService: FloatingChatService,
    private auth: NewAuthService
  ) { }

  ngOnInit(): void {
    // Load saved preference
    const savedInputType = localStorage.getItem('chat-input-type');
    if (savedInputType) {
      this.useTextArea = savedInputType === 'textarea';
    }
    this.initializeChat();
  }

  private initializeChat(): void {
    this.auth.user$.pipe(take(1)).subscribe(user => {
      if (user) {
        this.chatService.getThreadId().subscribe({
          next: (threadId) => {
            this.currentThreadId = threadId;
            this.setupMessageSubscription(user.user_id, threadId);
          },
          error: (error) => {
            console.error('Error getting thread ID:', error);
            this.messages = [{
              content: 'Error initializing chat. Please try again later.',
              role: 'system',
              type: 'error',
              timestamp: new Date().toISOString()
            }];
          }
        });
      }
    });
  }

  private setupMessageSubscription(userId: string, threadId: string): void {
    let messageCount = 0;
    this.chatService.subscribeToChatMessages(
      userId,
      threadId,
      (type, message) => {
        switch (type) {
          case 'added':
            this.messages.push(message);
            messageCount++;
            // Wait for a batch of messages before scrolling
            if (messageCount === 1) {
              // First message, schedule initial scroll
              setTimeout(() => {
                this.scrollToBottom();
              }, 150);
            } else {
              // Check if we're near bottom before scrolling for subsequent messages
              if (this.isNearBottom()) {
                this.scrollToBottom();
              }
            }
            break;
          case 'modified':
            const index = this.messages.findIndex(m => m.id === message.id);
            if (index !== -1) {
              this.messages[index] = message;
            }
            this.scrollToBottom();
            break;
          case 'removed':
            this.messages = this.messages.filter(m => m.id !== message.id);
            break;
        }
      }
    );
  }

  private isNearBottom(): boolean {
    if (this.messagesContainer) {
      const container = this.messagesContainer.nativeElement;
      const threshold = 100; // pixels from bottom
      return container.scrollHeight - container.scrollTop - container.clientHeight < threshold;
    }
    return true;
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  ngOnDestroy(): void {
    this.chatService.unsubscribeFromChatMessages();
  }

  scrollToBottom(instant: boolean = false) {
    if (this.messagesContainer) {
      if (instant) {
        const container = this.messagesContainer.nativeElement;
        container.scrollTop = container.scrollHeight;
      } else {
        setTimeout(() => {
          const container = this.messagesContainer.nativeElement;
          container.scrollTop = container.scrollHeight;
        }, 0);
      }
    }
  }

  sendMessage(): void {
    if (this.newMessage.trim() && !this.isWaitingForResponse) {
      this.isWaitingForResponse = true;

      // Clear input immediately
      const messageContent = this.newMessage.trim();
      this.newMessage = '';

      // Focus back on input
      if (this.messageInput) {
        this.messageInput.nativeElement.focus();
      }

      this.chatService.sendMessage(messageContent)
        .pipe(take(1))
        .subscribe({
          error: (error) => {
            console.error('Error sending message:', error);
            this.isWaitingForResponse = false;
          }
        });
    }
  }

  startNewSession(): void {
    this.messages = [];
    this.newMessage = '';
    this.isWaitingForResponse = true;

    // First get the authenticated user
    this.auth.user$.pipe(
      take(1)
    ).subscribe({
      next: (user) => {
        if (!user) {
          console.error('No authenticated user');
          this.isWaitingForResponse = false;
          return;
        }

        this.chatService.resetThread().subscribe({
          next: (threadId) => {
            this.currentThreadId = threadId;
            this.setupMessageSubscription(user.user_id, threadId);
            this.isWaitingForResponse = false;  // Reset after successful setup
          },
          error: (error) => {
            console.error('Error resetting thread:', error);
            this.isWaitingForResponse = false;
          }
        });
      },
      error: (error) => {
        console.error('Error getting user:', error);
        this.isWaitingForResponse = false;
      }
    });
  }

  toggleMinimize(): void {
    this.isTransitioning = true;
    this.isMinimized = !this.isMinimized;

    // If maximizing, check if new size would go out of bounds
    if (!this.isMinimized) {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const newWidth = this.size.width;
      const newHeight = this.size.height;

      // Calculate new position to keep chat within bounds
      const newPosition = {
        x: this.position.x,
        y: this.position.y
      };

      // Check horizontal bounds
      if (this.position.x + newWidth > windowWidth) {
        newPosition.x = Math.max(0, windowWidth - newWidth);
      }

      // Check vertical bounds
      if (this.position.y + newHeight > windowHeight) {
        newPosition.y = Math.max(0, windowHeight - newHeight);
      }

      // Update position if needed
      if (newPosition.x !== this.position.x || newPosition.y !== this.position.y) {
        this.position = newPosition;
      }

      // Brief delay to let the DOM update before scrolling
      requestAnimationFrame(() => {
        this.scrollToBottom(true);
      });
    }

    // Wait for transition to complete
    setTimeout(() => {
      this.isTransitioning = false;
    }, 300);
  }

  startDragging(event: MouseEvent): void {
    if (event.target instanceof Element && event.target.closest('.chat-header')) {
      this.isDragging = true;
      this.dragStart = { x: event.clientX - this.position.x, y: event.clientY - this.position.y };
      event.preventDefault();
    }
  }

  startResize(event: MouseEvent, direction: 'e' | 's' | 'se') {
    event.preventDefault();
    this.isResizing = true;
    this.isTransitioning = false; // Ensure no transitions during resize
    this.resizeType = direction;
    this.initialSize = { ...this.size };
    this.dragStart = { x: event.clientX, y: event.clientY };
  }

  onResize(event: MouseEvent) {
    if (this.isResizing) {
      const deltaX = event.clientX - this.dragStart.x;
      const deltaY = event.clientY - this.dragStart.y;

      requestAnimationFrame(() => {
        switch (this.resizeType) {
          case 'e':
            this.size.width = Math.max(300, this.initialSize.width + deltaX);
            break;
          case 's':
            this.size.height = Math.max(200, this.initialSize.height + deltaY);
            break;
          case 'se':
            this.size.width = Math.max(300, this.initialSize.width + deltaX);
            this.size.height = Math.max(200, this.initialSize.height + deltaY);
            break;
        }
      });
    }
  }

  stopResize() {
    this.isResizing = false;
    this.resizeType = null;
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isDragging) {
      event.preventDefault();
      event.stopPropagation();

      // Calculate new position directly from mouse position and initial offset
      const newX = event.clientX - this.dragStart.x;
      const newY = event.clientY - this.dragStart.y;

      // Get window dimensions
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      // Calculate boundaries based on chat size
      const chatWidth = this.isMinimized ? 300 : this.size.width;
      const chatHeight = this.isMinimized ? 48 : this.size.height;

      // Keep within window bounds
      this.position = {
        x: Math.max(0, Math.min(newX, windowWidth - chatWidth)),
        y: Math.max(0, Math.min(newY, windowHeight - chatHeight))
      };
    } else if (this.isResizing) {
      this.onResize(event);
    }
  }

  @HostListener('window:mouseup')
  onWindowMouseUp(): void {
    this.isDragging = false;
    this.isResizing = false;
    this.resizeType = null;
  }

  @HostListener('window:mouseleave')
  onWindowMouseLeave() {
    if (this.isDragging) {
      this.isDragging = false;
    }
    if (this.isResizing) {
      this.stopResize();
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    // Ensure chat stays within bounds when window is resized
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const chatWidth = this.isMinimized ? 300 : this.size.width;
    const chatHeight = this.isMinimized ? 48 : this.size.height;

    this.position = {
      x: Math.max(0, Math.min(this.position.x, windowWidth - chatWidth)),
      y: Math.max(0, Math.min(this.position.y, windowHeight - chatHeight))
    };
  }

  reportProblem(): void {
    // Add a message to help report the problem
    this.messages.push({
      content: 'Please describe the problem you are experiencing:',
      role: "assistant",
      type: "text",
      timestamp: new Date().toISOString()
    });
    // Flag next message as a problem report
    // You can implement additional logic here to handle the report
  }

  toggleInputType(): void {
    this.useTextArea = !this.useTextArea;
    // Save preference (you might want to implement this in a settings service)
    localStorage.setItem('chat-input-type', this.useTextArea ? 'textarea' : 'input');
  }
}
