import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PatientAddress } from 'src/app/models/patient.model';
import { AddressUSPSValidatorService } from 'src/app/services/address-validator/address-uspsvalidator.service';
import { DataService } from 'src/app/services/data.service';
import { DeviceOrderingService } from 'src/app/services/device-ordering/device-ordering.service';
import { DeviceVO, PatientVO } from 'src/app/services/device-ordering/value-objects/order.vo';
import { PatientDetailService } from 'src/app/services/patient-detail.service';
import { SnackService } from 'src/app/services/snack.service';
import { OrderManyDevicesDialogComponent } from '../dialogs/order-many-devices-dialog/order-many-devices-dialog.component';

@Component({
  selector: 'app-new-device-modal',
  templateUrl: './new-device-modal.component.html',
  styleUrls: ['./new-device-modal.component.scss'],
})
export class NewDeviceModalComponent implements OnInit, OnDestroy {
  addressForm: FormGroup;
  termsForm: FormGroup;
  showForm = true;
  addressValidated$: Observable<boolean>;
  loading = false;
  primaryAddress: PatientAddress;
  unsubscribe = new Subject();

  public selectedManufacturer = '';
  public selectedDevice: DeviceVO;

  states = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PatientVO,
    private dialogRef: MatDialogRef<NewDeviceModalComponent>,
    private dialog: MatDialog,
    public deviceService: DeviceOrderingService,
    private dataService: DataService,
    private fb: FormBuilder,
    private addressValidator: AddressUSPSValidatorService,
    private snackService: SnackService,
    private patientDetailService: PatientDetailService
  ) {}

  ngOnInit(): void {
    this.states = this.dataService.statesWithabbreviation;
    this.initializeNewOrderForm();
    this.patientDetailService.addresses$.pipe(takeUntil(this.unsubscribe)).subscribe((addresses) => {
      if (addresses.length > 0) {
        this.primaryAddress = addresses[0];
        this.addressForm.patchValue({
          address: this.primaryAddress.address ?? null,
          address2: this.primaryAddress.address2 ?? null,
          city: this.primaryAddress.city ?? null,
          state: this.primaryAddress.state ?? null,
          postalCode: this.primaryAddress?.postalCode ? String(this.primaryAddress.postalCode)?.trim() : String(this.primaryAddress?.zip).trim() ?? null,
        });
        this.listenAddressUpdates();
      }
    });
    this.deviceService.getAllDevices().pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  initializeNewOrderForm(): void {
    this.addressForm = this.fb.group({
      firstName: [this.data && this.data.firstName ? this.data.firstName : null, Validators.required],
      lastName: [this.data && this.data.lastName ? this.data.lastName : null, Validators.required],
      address: [this.primaryAddress?.address ?? null, Validators.required],
      address2: [this.primaryAddress?.address2 ?? 'N/A', Validators.required],
      city: [this.primaryAddress?.city ?? null, Validators.required],
      state: [this.primaryAddress?.state ?? null, Validators.required],
      postalCode: [
        this.primaryAddress?.postalCode ? String(this.primaryAddress.postalCode)?.trim() : String(this.primaryAddress?.zip).trim() ?? null,
        Validators.pattern(/^\d{5}$/),
      ],
      manufacturer: [''],
      type: [''],
      clientId: [this.data?.client_id ?? ''],
    });
    this.termsForm = this.fb.group({
      terms: ['', Validators.required],
    });
    this.addressForm.get('postalCode').markAllAsTouched();
  }

  handleManufacturerSelected() {
    this.deviceService.getDevicesByManufacturer(this.selectedManufacturer).pipe(take(1)).subscribe();
  }

  orderDevice() {
    const device = this.addressForm.getRawValue().type;
    this.dialogRef.close(device);
  }

  async onSubmit() {
    this.loading = true;
    const address = this.addressValidator.getAddressValidatorObject(this.addressForm);
    const validatorResponse = await this.validateAddress(address);
    if (validatorResponse.error) {
      this.addressValidated$ = of(false);
      this.snackService.genericSnackBar(validatorResponse.error, ['error-snackbar'], 5000);
    } else {
      this.addressValidated$ = of(true);
      this.showForm = false;
    }
    this.loading = false;
  }

  async onValidateAddress() {
    this.loading = true;
    const address = this.addressValidator.getAddressValidatorObject(this.addressForm);
    const validatorResponse = await this.validateAddress(address);
    if (validatorResponse.error) {
      this.addressValidated$ = of(false);
      this.snackService.genericSnackBar(validatorResponse.error, ['error-snackbar'], 5000);
    } else {
      this.snackService.genericSnackBar('Address found! ', ['success-snackbar'], 5000);
      this.addressValidator.setDataFromValidatorAddress(validatorResponse, this.addressForm);
      this.addressValidated$ = of(true);
    }
    this.loading = false;
  }

  acceptTerms(): void {
    this.dialogRef.close(this.addressForm.value);
  }

  openMultiplesDevicesView(): void {
    const dialogRef = this.dialog.open(OrderManyDevicesDialogComponent, {
      data: this.data,
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((resp) => this.dialogRef.close());
  }

  cancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private async validateAddress(address) {
    const resp = await this.addressValidator.validateAddress(address).pipe(take(1)).toPromise();
    return this.addressValidator.parseXMLtoJSON(resp, address);
  }

  private listenAddressUpdates(): void {
    combineLatest([
      this.addressForm.get('address').valueChanges,
      this.addressForm.get('address2').valueChanges,
      this.addressForm.get('state').valueChanges,
      this.addressForm.get('city').valueChanges,
      this.addressForm.get('postalCode').valueChanges,
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([address, address2, state, city, postalCode]) => (this.addressValidated$ = of(false)));
  }
}
