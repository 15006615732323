<h1>Send survey</h1>
<p *ngIf="currentPatient?.lastSurveyDate">Last Survey was sent: {{ lastSurveyDate?.toDate() | date : 'short' }}</p>
<mat-vertical-stepper [linear]="isLinear" #stepper>
  <mat-step [stepControl]="surveyOptionForm">
    <form [formGroup]="surveyOptionForm">
      <ng-template matStepLabel>Select an option</ng-template>
      <h2>Select survey</h2>
      <mat-radio-group class="survey-form-radio-group" formControlName="surveyOption">
        <mat-radio-button *ngFor="let survey of patientSurveys | keyvalue" [value]="survey.value.formId">
          {{ survey.value.title || survey.key }}
        </mat-radio-button>
      </mat-radio-group>
      <h2>Do you want to send the customer satisfaction survey via ?</h2>
      <mat-radio-group class="survey-form-radio-group" formControlName="viaOption">
        <mat-radio-button value="email">Email</mat-radio-button>
        <mat-radio-button value="sms">SMS</mat-radio-button>
      </mat-radio-group>
      <div class="survey-form-buttons">
        <button mat-button type="button" (click)="close()">Cancel</button>
        <button mat-button color="accent" matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Send</ng-template>
    <ng-container *ngIf="surveyOptionForm.get('viaOption').value === 'email'; else sms">
      <app-email-patient-dialog
        [email]="currentPatient?.email"
        [surveyKey]="surveyKey"
        [isSurveyLinkAdded]="true"
        [subject]="subject"
        (emailSent)="checkIfSurveySent($event)"
      ></app-email-patient-dialog>
    </ng-container>
    <ng-template #sms>
      <app-message-dialog [message]="surveyMessage" (smsSent)="checkIfSurveySent($event)"></app-message-dialog>
    </ng-template>
  </mat-step>
</mat-vertical-stepper>
