import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { AddSecondariesProvidersComponent } from 'src/app/dialogs/patient/add-secondaries-providers/add-secondaries-providers.component';
import { CoverageTypes, Languages, Patient } from 'src/app/models/patient.model';
import { DataService } from 'src/app/services/data.service';
import { InitialDataModel } from 'src/app/services/models/initial-data-model.service';
import { ProgramService } from 'src/app/services/programs/program.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AreYouSureModalComponent } from 'src/app/shared/are-you-sure-modal/are-you-sure-modal.component';
import { phoneNumberValidator } from 'src/app/shared/validators/phone-number.validator';
import { PatientDisenrollmentComponent } from './dialogs/patient-disenrollment/patient-disenrollment.component';

@Component({
  selector: 'app-basic-information',
  templateUrl: './basic-information.component.html',
  styleUrls: ['./basic-information.component.scss'],
})
export class BasicInformationComponent implements OnInit, OnDestroy {
  @Input()
  patient: Patient;
  @Input()
  expandPanel = false;
  @Output()
  toSavePatient = new EventEmitter<Patient>();
  @Output()
  changeEmail = new EventEmitter<{ email: string; patientId: string }>();
  basicinfoForm: FormGroup;
  genderTypes: string[] = [];
  providers: Patient[] = [];
  languages: string[] = Object.values(Languages);
  coverages: string[] = Object.values(CoverageTypes);
  showChangeEmailButton = false;
  programs$: Observable<any[]>;
  filteredProgramOptions$: Observable<any[]>;
  availablePrograms: any[] = [];
  languageModel = { label: 'Language', showLabel: true, appearance: 'outline', showOnlyActive: false };
  statusOptions$: Observable<string[]>;
  unsubscribe = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private initialModelService: InitialDataModel,
    private utilsService: UtilsService,
    private dialog: MatDialog,
    private programService: ProgramService
  ) {}

  ngOnInit(): void {
    this.genderTypes = this.dataService.genderTypes;
    this.getPatientStatusLabel();
    this.initializeForm();
    this.getPrograms();
    this.providers = (Object.values(this.initialModelService.allClientUsers) as Patient[])
      .filter((client) => client.roles.isClient)
      .sort((clientA, clientB) => this.utilsService.sortUsersByAlphabeticOrder(clientA, clientB));
    this.setBasicFormlisteners();
    this.setFilteredProgramOption();
  }

  getPatientStatusLabel() {
    this.statusOptions$ = this.dataService.getPatientStatusOptions();
  }

  getPrograms() {
    this.programs$ = this.programService.getPrograms();
    this.programs$.pipe(take(1)).subscribe((programs) => {
          this.availablePrograms = programs,
          this.availablePrograms.forEach((programObj) => {
            const programKey = programObj.value; 
            const programValue = this.patient.status?.[programKey] ?? ''; 
            const lastUpdateKey = `${programKey}_last_update`; 
            const lastUpdateValue = this.patient.status?.[lastUpdateKey] ?? ''; 
            (this.basicinfoForm.get('status') as FormGroup).addControl(
              programKey,
              new FormControl(programValue, []) 
            );
            (this.basicinfoForm.get('status') as FormGroup).addControl(
              lastUpdateKey,
              new FormControl(lastUpdateValue, []) 
            );
          });
        }); 
  }

  setFilteredProgramOption() {
    this.filteredProgramOptions$ = (this.basicinfoForm.get('status') as FormGroup).get('programInput').valueChanges.pipe(
      filter((data) => data && data.length > 0),
      map((data) => this.availablePrograms.filter((program) => program.name.toLowerCase().includes(data.toLowerCase()) || program.value.toLowerCase().includes(data.toLowerCase())))
    );
  }

  get programs() {
    return (this.basicinfoForm.get('status') as FormGroup)?.getRawValue()
      ? Object.keys((this.basicinfoForm.get('status') as FormGroup)?.getRawValue())
          .filter((key) => this.availablePrograms.some((program) => program.value.slice(0, 3) === key) && this.basicinfoForm.get('status').get(key).value)
          .map((key) =>
            this.availablePrograms.find((program) => {
              if (key === 'rpm') {
                return program.value === this.basicinfoForm.get('status').get('rpm_category').value;
              }
              return program.value.slice(0, 3) === key;
            })
          )
      : [];
  }

  selectProgram(program: string) {
    const normalizedProgram = program.slice(0, 3);
    (this.basicinfoForm.get('status') as FormGroup).get(normalizedProgram).setValue(true);
    (this.basicinfoForm.get('status') as FormGroup).get('programInput').reset();
    if (program.includes('rpm')) {
      (this.basicinfoForm.get('status') as FormGroup).get('rpm_category').setValue(program);
    }
  }

  removeProgram(program: string) {
    const normalizedProgram = program.slice(0, 3);
    (this.basicinfoForm.get('status') as FormGroup).get(normalizedProgram).setValue(false);
  }

  initializeForm() {
    this.basicinfoForm = this.fb.group({
      firstName: [this.patient.firstName ?? '', Validators.required],
      lastName: [this.patient.lastName ?? '', Validators.required],
      middleName: [this.patient.middleName ?? ''],
      gender: [this.patient.gender ?? '', Validators.required],
      email: [this.patient.email ?? '', [Validators.required, Validators.email]],
      language: [this.patient.language ?? ''],
      active_rpm: [this.patient.active_rpm ?? ''],
      height_inches: [this.patient.height_inches ?? ''],
      dob: [typeof this.patient.dob === 'string' ? new Date(this.patient.dob) : this.patient.dob?.toDate() ?? ''],
      weight_lbs: [this.patient.weight_lbs ?? ''],
      notes: [this.patient.notes ?? ''],
      provider_id: [this.initialModelService?.allClientUsers[this.patient.provider_id] ?? '', Validators.required],
      sms_contact: [this.patient.sms_contact ? this.getNormalizedPhoneValue(this.patient.sms_contact) : '', phoneNumberValidator],
      sms_active: [''],
      timezone: [this.patient.timezone ?? ''],
      primary_coverage_type: [this.patient.primary_coverage_type ?? ''],
      emr_data: this.fb.group({
        mrn: [this.fillEMRdataFromPatient() ?? ''],
        emr_guid: [this.fillEMRdataGUIDFromPatient() ?? ''],
      }),
      hubspot_id: [this.patient.hubspot_id ?? ''],
      status: this.fb.group({
        programInput: [''],
        rpm_category: [this.patient.status?.rpm ? this.patient.status?.rpm_category ?? 'rpms' : ''],
        rpm:[this.patient.status?.rpm ?? ''],
        rpm_last_update:[this.patient.status?.rpm_last_update ?? ''],
        deceased: [this.patient.status?.deceased ?? ''],
        disenrollment_ccm_comments: [this.patient.status?.disenrollment_ccm_comments ?? ''],
        disenrollment_rpm_comments: [this.patient.status?.disenrollment_rpm_comments ?? ''],
        label: [this.patient.status?.label ?? ''],
      }),
      contact_consent: this.fb.group({
        email: [this.patient.contact_consent?.email ?? ''],
        phone: [this.patient.contact_consent?.phone ?? ''],
        sms: [this.patient.contact_consent?.sms ?? ''],
      }),
    });
    if (!this.patient.status.active) {
      this.basicinfoForm.get('status').get('programInput').disable();
    }
  }

  setBasicFormlisteners(): void {
    this.basicinfoForm
      .get('email')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe((email) => {
        this.showChangeEmailButton = email !== this.patient.email;
      });
    this.basicinfoForm
      .get('status')
      .get('label')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe((status) => {
        this.basicinfoForm.get('status').get('programInput').enable();
        this.basicinfoForm
          .get('status')
          .get('deceased')
          .setValue(status === 'Deceased');
        if (status.includes('Inactive') || status === 'Deceased') {
          this.availablePrograms.forEach(control => {
            const controlName = control.value; 
            if (this.basicinfoForm.get('status').get(controlName)) {
              this.basicinfoForm.get('status').get(controlName).setValue(false); 
          }
        });
          this.basicinfoForm.get('status').get('rpm').setValue(false);
          this.basicinfoForm.get('status').get('programInput').reset();
          this.basicinfoForm.get('status').get('programInput').disable();
        }
      });
  }

  async saveBasicInfo(): Promise<void> {
    const priorPatient = { ...this.patient };
    const formValue = this.basicinfoForm.getRawValue();
    const filteredStatus = {};
    Object.entries(formValue.status).forEach(([key, value]) => {
      if (value !== "") {
        filteredStatus[key] = value;
      }
    });
    this.patient = {
      ...this.basicinfoForm.getRawValue(),
      status:filteredStatus,
      dates: this.patient.dates ?? {},
      user_id: this.patient.user_id,
      secondary_providers: this.patient.secondary_providers ?? [],
    };
    delete (this.patient.status as any).programInput;
    if (priorPatient.status.rpm && !this.patient.status.rpm) {
      await this.openDisenrollmentDialog(priorPatient, this.patient, 'rpm');
    }
    if (priorPatient.status.ccm && !this.patient.status.ccm) {
      await this.openDisenrollmentDialog(priorPatient, this.patient, 'ccm');
    }
    if (this.basicinfoForm.get('sms_contact').value) {
      this.patient.sms_contact = `+1${this.basicinfoForm.get('sms_contact').value.replace(/-/g, '')}`;
    }
    if (priorPatient.status?.rpm !== this.patient.status?.rpm) {
      this.patient.status.rpm_last_update = new Date();
    }
    const keyNames = this.availablePrograms.map(keyObj => keyObj.value);
    Object.entries(this.patient.status).forEach(([key, value]) => {
      if (keyNames.includes(key)) {
        if (priorPatient.status?.[key] !== this.patient.status?.[key]) {
          this.patient.status[`${key}_last_update`] = new Date();
        }
      }
    });
    const programValues = this.availablePrograms.map(program => this.patient.status[program.value]);
    if (programValues.some(value => value) || this.patient.status?.rpm) {
      this.patient.status.active = true;
    } else {
      this.patient.status.active = false;
    }
    this.patient.dob =
      typeof this.patient.dob === 'string' ? this.patient.dob : `${this.patient.dob.getMonth() + 1}/${this.patient.dob.getDate()}/${this.patient.dob.getFullYear()}`;
    if (this.patient.dates) {
      this.patient.dates.dob = this.patient.dob;
    }
    if (this.basicinfoForm.get('provider_id').value?.user_id) {
      this.patient.provider_id = this.basicinfoForm.get('provider_id').value?.user_id;
    }
    this.toSavePatient.emit(this.patient);
  }

  openChangeEmailDialog(): void {
    // open dialog to confirm deactivation
    const modal = this.dialog.open(AreYouSureModalComponent, {
      data: {
        title: `Do you want to change the patient's login email?`,
        body: `The user will need to log in again with the new email.`,
        hideCancelBtn: false,
        cancelButton: 'Cancel',
        confirmButton: 'Yes, change the login email',
      },
    });
    // refresh the page after closed
    modal.afterClosed().subscribe(async (result) => {
      if (result) {
        this.changeEmail.emit({ email: this.basicinfoForm.get('email').value, patientId: this.patient.user_id });
        this.showChangeEmailButton = false;
      }
    });
  }

  openSecondaryProviderPanel(): void {
    const dialogRef = this.dialog.open(AddSecondariesProvidersComponent, {
      width: '700px',
      data: { providers: this.patient.secondary_providers },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.patient.secondary_providers = result;
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private async openDisenrollmentDialog(priorPatient: Patient, patient: Patient, key: string) {
    return new Promise((resolve, reject) => {
      // open dialog to confirm deactivation
      const dialog = this.dialog.open(PatientDisenrollmentComponent, {
        width: '600px',
        disableClose: true,
        data: {
          program: key,
        },
      });
      dialog.afterClosed().subscribe((result) => {
        const disenrollmentCommentKey = key === 'rpm' ? 'disenrollment_rpm_comments' : 'disenrollment_ccm_comments';
        this.patient.status[disenrollmentCommentKey] = result?.reason;
        resolve(true);
      });
    });
  }

  private getNormalizedPhoneValue(phone: string) {
    const phoneWithoutSign = phone.substring(2).trim();
    return this.utilsService.getPhoneNumberFormated(phoneWithoutSign);
  }

  private fillEMRdataFromPatient(): string {
    return this.patient?.emr_data ? this.patient?.emr_data.mrn : this.patient?.emr_id;
  }

  private fillEMRdataGUIDFromPatient(): string {
    return this.patient?.emr_data ? this.patient?.emr_data?.emr_guid : this.patient?.emr_id;
  }
}
