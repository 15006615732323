<div #chatWindow 
     class="chat-window mat-elevation-z4"
     [class.minimized]="isMinimized"
     [class.transitioning]="isTransitioning"
     [style.transform]="'translate(' + position.x + 'px, ' + position.y + 'px)'"
     [style.width.px]="size.width"
     [style.height.px]="size.height">
  
  <!-- Chat Header -->
  <div class="chat-header mat-elevation-z2"
       (mousedown)="startDragging($event)">
    <span class="title">{{title}}</span>
    <div class="actions">
      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
        <mat-icon>settings</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="startNewSession()">
          <mat-icon>refresh</mat-icon>
          <span>New session</span>
        </button>
        <button mat-menu-item (click)="reportProblem()">
          <mat-icon>bug_report</mat-icon>
          <span>Report problem</span>
        </button>
        <button mat-menu-item (click)="toggleInputType()">
          <mat-icon>{{ useTextArea ? 'short_text' : 'notes' }}</mat-icon>
          <span>{{ useTextArea ? 'Use single line' : 'Use multi-line' }}</span>
        </button>
      </mat-menu>
      <button mat-icon-button (click)="toggleMinimize(); $event.stopPropagation()">
        <mat-icon>{{isMinimized ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
      </button>
    </div>
  </div>

  <!-- Chat Content -->
  <div class="chat-content" [class.hidden]="isMinimized">
    <div #messagesContainer class="messages-container">
      <div *ngFor="let message of messages" 
           class="message" 
           [class.sent]="message.role === 'user'">
        <div class="message-bubble mat-elevation-z1">
          <ng-container *ngIf="message.role === 'assistant' && !message.content">
            <div class="loading-dots">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </ng-container>
          <ng-container *ngIf="message.content">
            <div *ngIf="message.role === 'assistant'" [innerHTML]="message.content | markdown"></div>
            <div *ngIf="message.role === 'user'">{{message.content}}</div>
          </ng-container>
        </div>
        <div class="message-time">
          {{message.timestamp | date:'shortTime'}}
        </div>
      </div>
    </div>

    <!-- Input Area -->
    <div class="input-area mat-elevation-z1">
      <mat-form-field appearance="outline" class="message-input">
        <ng-container *ngIf="!useTextArea">
          <input matInput
                 [(ngModel)]="newMessage"
                 placeholder="Type a message..."
                 (keyup.enter)="sendMessage()"
                 #messageInput>
        </ng-container>
        <ng-container *ngIf="useTextArea">
          <textarea matInput
                   [(ngModel)]="newMessage"
                   placeholder="Type a message..."
                   (keyup.enter)="$event.shiftKey ? null : sendMessage()"
                   cdkTextareaAutosize
                   #messageInput
                   [cdkAutosizeMinRows]="2"
                   [cdkAutosizeMaxRows]="6"></textarea>
        </ng-container>
      </mat-form-field>
      <button mat-icon-button
              [disabled]="!newMessage.trim() || isWaitingForResponse"
              (click)="sendMessage()">
        <mat-icon>send</mat-icon>
      </button>
    </div>
  </div>

  <!-- Resize Handles -->
  <div class="resize-handle e" 
       (mousedown)="startResize($event, 'e')"
       (mousemove)="$event.stopPropagation()"></div>
  <div class="resize-handle s" 
       (mousedown)="startResize($event, 's')"
       (mousemove)="$event.stopPropagation()"></div>
  <div class="resize-handle se" 
       (mousedown)="startResize($event, 'se')"
       (mousemove)="$event.stopPropagation()"></div>
</div>
