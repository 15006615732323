import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { firestore } from 'firebase';
import { take } from 'rxjs/operators';
import { Patient } from 'src/app/models/patient.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PatientService } from 'src/app/services/patient.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-survey-dialog',
  templateUrl: './survey-dialog.component.html',
  styleUrls: ['./survey-dialog.component.scss'],
})
export class SurveyDialogComponent implements OnInit {
  surveyOptionForm: FormGroup;
  isLinear = true;
  hasSurveyLink = true;
  currentPatient: Patient;
  subject = 'Happy with Welby? | Let us know how you feel';
  surveyMessage = '';
  surveyUrl = '';
  surveyKey = 'welby_survey';
  lastSurveyDate: firestore.Timestamp;
  surveyConf: any;
  patientSurveys: any;
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<SurveyDialogComponent>,
    private patientService: PatientService,
    private utilsService: UtilsService,
    private fs: FirestoreService
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentPatient = this.patientService.currentPatientService;
    this.initializeForm();
    this.lastSurveyDate = this.data.lastSurveyDate;
    this.patientSurveys = await this.utilsService.getSurveyForPatients(this.currentPatient.client_responsible_id);
  }

  initializeForm() {
    this.surveyOptionForm = this.fb.group({
      viaOption: ['', Validators.required],
      surveyOption: ['', Validators.required],
    });
    this.surveyOptionForm.get('surveyOption').valueChanges.subscribe(async (value) => {
      this.surveyKey = value;
      await this.buildSurveyMessage(value);
    });
  }

  /**
   *
   * Builds a survey message by fetching a survey URL from the TypeForm API
   *
   * @param value The form ID of the survey to be sent
   * @returns Promise<void>
   * This method:
   * 1. Gets a shortened survey URL using the utils service
   * 2. Stores the URL in the component's surveyUrl property
   * 3. Creates a message with the survey URL to be sent to the patient
   */
  async buildSurveyMessage(value: string): Promise<void> {
    const surveyUrlResponse = await this.utilsService.getSurveyUrl(value, this.currentPatient.client_responsible_id, this.currentPatient.user_id).pipe(take(1)).toPromise();
    this.surveyUrl = surveyUrlResponse.shortUrl;
    this.surveyMessage = `We want to hear from you. Please fill out the following survey: ${this.surveyUrl}`;
  }

  checkIfSurveySent(surveySent: boolean) {
    if (surveySent) {
      this.fs.update(`users/${this.currentPatient.user_id}`, { lastSurveyDate: new Date() });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
