import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe that replaces all occurrences of a search string with a replacement string in the input value.
 *
 * @example
 * ```html
 * <!-- Converts "hello_world" to "hello world" -->
 * {{ "hello_world" | replace:'_':' ' }}
 * ```
 * @usageNotes
 * If any of the input parameters (value, searchValue, replaceValue) are null or undefined,
 * the original value is returned unchanged.
 */
@Pipe({
  name: 'replace',
})
export class ReplacePipe implements PipeTransform {
  /**
   * Replaces all occurrences of a search string with a replacement string in the input value.
   *
   * @param value The input string to transform.
   * @param searchValue The string to search for.
   * @param replaceValue The string to replace the search value with.
   * @returns The transformed string.
   */
  transform(value: string, searchValue: string, replaceValue: string): string {
    if (!value || !searchValue || !replaceValue) {
      return value;
    }
    return value.split(searchValue).join(replaceValue);
  }
}
