import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { ChatmessageVO } from './chat-message.vo';
import { environment } from '../../../../environments/environment';
import { NewAuthService } from '../../../services/auth/new-auth-service.service';
import { switchMap, map, take } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { PatientService } from '../../../services/patient.service';

interface ThreadResponse {
  threadId: string;
}

interface NewThreadResponse {
  threadId: string;
}

interface MarkusAskRequest {
  uid: string;
  patientUid?: string;
  message: string;
}

type MessageChangeType = 'added' | 'modified' | 'removed';

@Injectable({
  providedIn: 'root'
})
export class FloatingChatService {
  private readonly apiUrl = environment.welbyEndpoint;
  private messageUnsubscribe: Subscription | null = null;

  constructor(
    private http: HttpClient,
    private auth: NewAuthService,
    private afs: AngularFirestore,
    private patientService: PatientService
  ) { }

  getMessages(): Observable<ChatmessageVO[]> {
    return this.auth.user$.pipe(
      map(user => {
        if (!user) {
          throw new Error('No authenticated user');
        }
        return user;
      }),
      switchMap(user => {
        return this.http.post<ChatmessageVO[]>(`${this.apiUrl}/api/v1/markus/messages`, { uid: user.user_id });
      })
    );
  }

  getThreadId(): Observable<string> {
    return this.auth.user$.pipe(
      map(user => {
        if (!user) {
          throw new Error('No authenticated user');
        }
        return user;
      }),
      switchMap(user => {
        return this.http.post<ThreadResponse>(`${this.apiUrl}/api/v1/markus/thread`, { uid: user.user_id });
      }),
      map(response => response.threadId)
    );
  }

  createNewThread(): Observable<string> {
    return this.auth.user$.pipe(
      take(1),
      map(user => {
        if (!user) {
          throw new Error('No authenticated user');
        }
        return user;
      }),
      switchMap(user => {
        return this.http.post<NewThreadResponse>(`${this.apiUrl}/api/v1/markus/thread/new`, { uid: user.user_id });
      }),
      map(response => response.threadId)
    );
  }

  resetThread(): Observable<string> {
    if (this.messageUnsubscribe) {
      this.unsubscribeFromChatMessages();
    }
    return this.createNewThread();
  }

  subscribeToChatMessages(
    uid: string,
    threadId: string,
    onMessageChange: (type: MessageChangeType, message: ChatmessageVO) => void
  ): void {
    if (this.messageUnsubscribe) {
      console.warn('Already subscribed to chat messages. Unsubscribing before re-subscribing.');
      this.unsubscribeFromChatMessages();
    }

    try {
      console.log(`Subscribing to chat messages for thread: ${threadId}`);

      const messagesRef = this.afs
        .collection('users')
        .doc(uid)
        .collection('chats')
        .doc(threadId)
        .collection('messages', ref => ref.orderBy('timestamp', 'asc'));

      this.messageUnsubscribe = messagesRef.stateChanges().subscribe(
        (changes) => {
          changes.forEach((change) => {
            const message: ChatmessageVO = {
              id: change.payload.doc.id,
              ...change.payload.doc.data()
            } as ChatmessageVO;

            // Handle image type messages
            if (message.type === 'image' && typeof message.content === 'string') {
              this.imageUrlToBase64(message.content).then((base64) => {
                const messageWithImage = { ...message, base64 };
                onMessageChange(change.type, messageWithImage);
              });
            } else {
              onMessageChange(change.type, message);
            }

            console.log(`Message ${change.type}: ${message.id}`);
          });
        },
        (error) => {
          console.error('Error with chat message subscription:', error);
          this.unsubscribeFromChatMessages();
        }
      );

      console.log('Successfully subscribed to chat messages');
    } catch (error) {
      console.error('Error subscribing to chat messages:', error);
      throw error;
    }
  }

  /**
   * Unsubscribes from real-time chat messages.
   */
  unsubscribeFromChatMessages(): void {
    if (this.messageUnsubscribe) {
      this.messageUnsubscribe.unsubscribe();
      this.messageUnsubscribe = null;
      console.log('Unsubscribed from chat messages');
    }
  }

  /**
   * Converts an image URL to base64 string
   */
  private async imageUrlToBase64(url: string): Promise<string> {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error converting image to base64:', error);
      throw error;
    }
  }

  sendMessage(message: string): Observable<ChatmessageVO> {
    return this.auth.user$.pipe(
      take(1),
      map(user => {
        if (!user) {
          throw new Error('No authenticated user');
        }
        const currentPatient = this.patientService.currentPatientService;
        const payload: MarkusAskRequest = {
          uid: user.user_id,
          message
        };
        
        // Only add patientUid if a patient is selected
        if (currentPatient) {
          payload.patientUid = currentPatient.user_id;
        }
        
        return payload;
      }),
      switchMap((payload: MarkusAskRequest) => {
        return this.http.post<ChatmessageVO>(`${this.apiUrl}/api/v1/markus/ask`, payload);
      })
    );
  }

  startNewSession(): Observable<ChatmessageVO> {
    return this.http.post<ChatmessageVO>(`${this.apiUrl}/sessions`, {});
  }
}
