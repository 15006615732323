import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { SecureEmail } from 'src/app/models/secure-email.model';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { SecureEmailService } from 'src/app/services/communications/secure-email.service';
import { DeviceOrderingService } from 'src/app/services/device-ordering/device-ordering.service';
import { DeviceVO } from 'src/app/services/device-ordering/value-objects/order.vo';
import { SnackService } from 'src/app/services/snack.service';
import { environment } from 'src/environments/environment';
import { AreYouSureModalComponent } from 'src/app/shared/are-you-sure-modal/are-you-sure-modal.component';

@Component({
  selector: 'app-order-many-devices-dialog',
  templateUrl: './order-many-devices-dialog.component.html',
  styleUrls: ['./order-many-devices-dialog.component.scss'],
})
export class OrderManyDevicesDialogComponent implements OnInit {
  public selectedManufacturer = '';
  public selectedDevice: DeviceVO;
  currentUser;
  welbyEmailRegex = /^[\w-\.]+@(getwelby+\.)+[\w-]{2,4}$/;
  form: FormGroup;
  loading = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<OrderManyDevicesDialogComponent>,
    public deviceService: DeviceOrderingService,
    private secureEmailService: SecureEmailService,
    private authService: NewAuthService,
    private snackService: SnackService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      devices: this.fb.array([]),
    });
    this.addDevice();
    this.currentUser = this.authService.user;
  }

  get devices() {
    return this.form.controls['devices'] as FormArray;
  }

  handleManufacturerSelected(index) {
    this.deviceService
      .getDevicesByManufacturer(this.devices.at(index).get('manufacturer').value)
      .pipe(take(1))
      .subscribe((data) => {
        this.devices.at(index).get('devicesOptions').setValue(data);
      });
  }

  addDevice(): void {
    const deviceForm = this.fb.group({
      manufacturer: [''],
      type: [''],
      devicesOptions: [[]],
    });
    (this.form.get('devices') as FormArray).push(deviceForm);
  }

  deleteDevice(index): void {
    (this.form.get('devices') as FormArray).removeAt(index);
  }

  sendEmail(): void {
    const emailData = this.transformFormIntoEmailbody();
    this.sendSecureEmail(emailData);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  getValidWelbyEmail(): string {
    const email: string = this.currentUser?.email;
    return this.welbyEmailRegex.test(email) ? email : environment.defaultWelbyEmail;
  }

  prepareContent(): string {
    let content = `Hello Welby team, Please place this order manually: \n`;
    content = `${content} Patient:  ${this.data.firstName} ${this.data.lastName} \n`;
    this.devices.value.forEach((device) => {
      content = `${content} \t * ${device.manufacturer ?? 'No data'} - ${device.type.sku_display ?? 'No data'} \n`;
    });
    content = `${content} Address: ${this.data.address.street} \n`;
    content = `${content} City: ${this.data.address.city} \n`;
    content = `${content} State: ${this.data.address.city} \n\n`;
    content = `${content} Order created by: ${this.currentUser.firstName} ${this.currentUser.lastName} \n`;
    content = `${content} Best regards`;
    return content;
  }

  private transformFormIntoEmailbody(): SecureEmail {
    const emailInformation: SecureEmail = {
      recipients: [environment.defaultOrdersWelbyEmail],
      headers: {
        subject: 'Request Order - Many devices',
        from: 'admin@getwelby.com',
      },
      content: {
        'text/plain': this.prepareContent(),
      },
      forceSecureNotification: false,
    };

    return emailInformation;
  }

  private sendSecureEmail(emailInformation: SecureEmail): void {
     const dialog = this.dialog.open(AreYouSureModalComponent, {
          width: '500px',
          data: {
            title: `Multiple orders information`,
            body: `Orders will not be created automatically but will be sent to orders@welby.care to be managed manually.`,
            hideCancelBtn: false,
            cancelButton: 'Cancel',
            confirmButton: 'Confirm'
          },
        });
        dialog.afterClosed().subscribe(async (result) => {
          if (result) {
            console.log(result)
            this.secureEmailService.sendEmail(emailInformation).subscribe(
              (resp) => {
                this.snackService.genericSnackBar('Email has been sent successfully', ['success-snackbar']);
                this.loading = false;
                this.dialogRef.close();
              },
              (error) => {
                console.error('error', error);
                this.snackService.genericSnackBar(`Internal error. Description: ${error.message}`, ['error-snackbar']);
                this.loading = false;
                this.dialogRef.close();
              }
            );
          }
        });
    
  }
}
